<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="iam.chefDetails.meals.edit"
              v-if="isEditing"
            ></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.en"
                    :placeholder="i18n('common.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.ar"
                    :placeholder="i18n('common.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.section') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <TomSelect v-model="model.sectionId" class="!w-full">
                    <option
                      v-for="(section, index) in kitchenSections"
                      :key="index"
                      :value="section.id"
                    >
                      {{ presenterLocalization(section, 'name') }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.category') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <TomSelect v-model="model.categoryId" class="!w-full">
                    <option
                      v-for="(category, index) in kitchenCategories"
                      :key="index"
                      :value="category.id"
                    >
                      {{ presenterLocalization(category, 'name') }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.preparationTime') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <TomSelect v-model="model.orderBefore" class="!w-full">
                    <option
                      v-for="(orderBefore, index) in orderBeforeEnum"
                      :key="index"
                      :value="orderBefore"
                    >
                      {{ orderBefore }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <!-- <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.price') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="number"
                    step="0.01"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.price"
                    :placeholder="i18n('iam.chefDetails.meals.fields.price')"
                  />
                </div>
              </div> -->
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.addons') }}
                </h4>
                <div
                  v-if="model.addons.length === 0"
                  class="lg:col-span-8 col-span-12 lg:mt-0 mt-2 grid gap-y-2 items-center"
                >
                  <button
                    type="button"
                    class="btn bg-theme-33 text-black w-fit cursor-pointer"
                    @click="initAddons"
                  >
                    {{ i18n('iam.chefDetails.meals.addAddons') }}
                  </button>
                </div>
                <div
                  v-else
                  class="lg:col-span-8 col-span-12 lg:mt-0 mt-2 grid gap-y-2 items-center"
                >
                  <div
                    class="grid grid-cols-12 relative gap-3"
                    v-for="(addon, index) in model.addons"
                    :key="`activity-${index}`"
                  >
                    <div class="lg:col-span-4 col-span-11 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="addon.name.en"
                        :placeholder="i18n('common.english')"
                      />
                    </div>
                    <div class="lg:col-span-4 col-span-11 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="addon.name.ar"
                        :placeholder="i18n('common.arabic')"
                      />
                    </div>
                    <div
                      class="2xl:col-span-3 lg:col-span-2 col-span-11 lg:mt-0 mt-2"
                    >
                      <input
                        id="crud-form-1"
                        type="number"
                        class="form-control w-full dark:placeholder-white"
                        v-model="addon.price"
                        :placeholder="
                          i18n('iam.chefDetails.meals.fields.price')
                        "
                      />
                    </div>
                    <div
                      class="2xl:col-span-1 lg:col-span-2 flex lg:items-center lg:justify-end flex-col justify-center items-center lg:flex-row-reverse gap-2 row-start-1 row-end-4 col-start-12 col-end-12 lg:row-auto"
                    >
                      <svg
                        v-if="index === model.addons.length - 1"
                        @click="addAddon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="cursor-pointer"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="green"
                          d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        />
                      </svg>
                      <svg
                        @click="removeAddon(index)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="cursor-pointer"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="#ff0000"
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.sizes') }}
                </h4>
                <div
                  v-if="model.sizes.length === 0"
                  class="lg:col-span-8 col-span-12 lg:mt-0 mt-2 grid gap-y-2 items-center"
                >
                  <button
                    type="button"
                    class="btn bg-theme-33 text-black w-fit cursor-pointer"
                    @click="initSizes"
                  >
                    {{ i18n('iam.chefDetails.meals.addSizes') }}
                  </button>
                </div>
                <div
                  v-else
                  class="lg:col-span-8 col-span-12 lg:mt-0 mt-2 grid gap-y-2 items-center"
                >
                  <div
                    class="grid grid-cols-12 relative gap-3"
                    v-for="(size, index) in model.sizes"
                    :key="`activity-${index}`"
                  >
                    <div class="lg:col-span-4 col-span-11 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="size.name.en"
                        :placeholder="i18n('common.english')"
                      />
                    </div>
                    <div class="lg:col-span-4 col-span-11 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="size.name.ar"
                        :placeholder="i18n('common.arabic')"
                      />
                    </div>
                    <div
                      class="2xl:col-span-3 lg:col-span-2 col-span-11 lg:mt-0 mt-2"
                    >
                      <input
                        id="crud-form-1"
                        type="number"
                        class="form-control w-full dark:placeholder-white"
                        v-model="size.price"
                        :placeholder="
                          i18n('iam.chefDetails.meals.fields.price')
                        "
                      />
                    </div>
                    <div
                      class="2xl:col-span-1 lg:col-span-2 flex lg:items-center lg:justify-end flex-col justify-center items-center lg:flex-row-reverse gap-2 row-start-1 row-end-4 col-start-12 col-end-12 lg:row-auto"
                    >
                      <svg
                        v-if="index === model.sizes.length - 1"
                        @click="addSize"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="cursor-pointer"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="green"
                          d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        />
                      </svg>
                      <svg
                        v-show="model.sizes.length > 1"
                        @click="removeSize(index)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="cursor-pointer"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="#ff0000"
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.video') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && videoUrl">
                    <video
                      controls
                      autoplay
                      style="width: 100%; height: 150px"
                      type="video/*"
                    >
                      <source :src="videoUrl" />
                      Your browser does not support the video tag.
                    </video>
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.video = undefined"
                    />
                  </div>
                  <Uploader
                    ref="uploaderVideo"
                    :maxSize="4"
                    type="video/mp4"
                    :path="storagePath"
                    v-else
                  ></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('iam.chefDetails.meals.fields.images') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imagesUrls">
                    <div class="flex gap-0 flex-wrap">
                      <div
                        class="relative h-32 w-36"
                        v-for="(image, index) in imagesUrls"
                        :key="index"
                      >
                        <img :src="image" class="object-cover h-full w-full" />
                        <img
                          src="/images/deleteIcon.png"
                          alt=""
                          class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                          @click="deleteImage(index)"
                        />
                      </div>
                    </div>
                    <Uploader
                      ref="uploaderImagesAdd"
                      type="image/*"
                      message="common.addMoreImages"
                      :path="storagePath"
                      :multiple="true"
                    ></Uploader>
                  </div>
                  <Uploader
                    ref="uploaderImagesNew"
                    :path="storagePath"
                    type="image/*"
                    :multiple="true"
                    v-else
                  ></Uploader>
                </div>
              </div>
            </div>
            <!-- END -->
            <div class="flex justify-center items-center mt-10">
              <AppButton
                type="button"
                class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
                :class="!saveLoading ? 'w-24' : ''"
                :disabled="saveLoading || findLoading"
                :loading="saveLoading"
                @click="doSubmit()"
              >
                <strong>{{ i18n('common.save') }}</strong>
                <template v-slot:loading>
                  <app-i18n code="common.loading"></app-i18n>
                  <LoadingIcon
                    icon="three-dots"
                    color="#FFFFFF"
                    style="margin: 0 4px"
                  />
                </template>
              </AppButton>
              <button
                type="button"
                class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                @click="doCancel"
              >
                {{ i18n('common.cancel') }}
              </button>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { collection, doc, getFirestore } from 'firebase/firestore'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.chefs')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.chefs')
    }
  },
  props: {
    chefId: {
      type: String,
      required: false
    },
    mealId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      model: null,
      args: [],
      branchOptions: [],
      errorMessage: null,
      kitchenCategories: [],
      kitchenSections: [],
      orderBeforeEnum: [
        'immediate',
        'oneHour',
        'threeHours',
        'sixHours',
        'nineHours',
        'twelveHours',
        'day',
        'twoDays',
        'threeDays'
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'meal/form/record',
      sections: 'user/view/sections',
      findLoading: 'meal/form/findLoading',
      saveLoading: 'meal/form/saveLoading',
      categories: 'category/list/categories'
    }),
    storagePath() {
      return `chefs/${this.chefId}/meals/${this.mealId}`
    },
    isEditing() {
      return !!this.mealId
    },
    generatedId() {
      return doc(collection(getFirestore(), 'ids')).id
    },
    filesToSaveImagesAdd() {
      return this.$refs.uploaderImagesAdd?.filesToSave || []
    },
    filesToSaveImagesNew() {
      return this.$refs.uploaderImagesNew?.filesToSave || []
    },
    filesToSaveVideo() {
      return this.$refs.uploaderVideo?.filesToSave || []
    },
    videoUrl() {
      return this.model && this.model.video ? this.model.video : null
    },
    imagesUrls() {
      return this.model && this.model.images && this.model.images.length > 0
        ? this.model.images
        : null
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind({ chefId: this.chefId, mealId: this.mealId })
      await this.listAllCategories()
      await this.doFindKitchen(this.chefId)
    } else {
      this.doNew()
    }
    this.model = this.record || {
      name: {
        en: '',
        ar: ''
      },
      description: {
        en: '',
        ar: ''
      },
      categoryId: '',
      sectionId: '',
      // price: '',
      orderBefore: this.orderBeforeEnum[0],
      images: [],
      addons: [],
      sizes: [],
      video: ''
    }

    this.kitchenCategories = this.categories
    this.kitchenSections = this.sections

    if (this.isEditing) {
      this.model.addons =
        this.record && this.record.addons.length > 0 ? this.record.addons : []
      this.model.sizes =
        this.record && this.record.sizes.length > 0 ? this.record.sizes : []
    }
  },
  methods: {
    ...mapActions({
      doNew: 'meal/form/doNew',
      doFind: 'meal/form/doFind',
      doUpdate: 'meal/form/doUpdate',
      doFindKitchen: 'user/view/doFindKitchen',
      listAllCategories: 'category/list/listAllCategories'
    }),
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    initAddons() {
      this.model.addons = [
        { id: this.generatedId, name: { en: '', ar: '' }, price: '' }
      ]
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    addAddon() {
      this.model.addons.push({
        id: this.generatedId,
        name: { en: '', ar: '' },
        price: ''
      })
    },
    removeAddon(index) {
      this.model.addons.splice(index, 1)
    },
    initSizes() {
      this.model.sizes = [
        { id: this.generatedId, name: { en: '', ar: '' }, price: '' }
      ]
    },
    addSize() {
      this.model.sizes.push({
        id: this.generatedId,
        name: { en: '', ar: '' },
        price: ''
      })
    },
    removeSize(index) {
      this.model.sizes.splice(index, 1)
    },
    isFormValid() {
      if (this.model.name.en.length == 0 && this.model.name.ar.length == 0) {
        this.errorMessage = `notifications.create.popup.emptyFields-${
          this.isRTL ? 'اسم' : 'name'
        }`
        return false
      }
      if (
        this.model.description.en.length == 0 &&
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = `notifications.create.popup.emptyFields-${
          this.isRTL ? 'وصف' : 'description'
        }`
        return false
      }

      // if (this.model.price.length == 0) {
      //   this.errorMessage = `notifications.create.popup.emptyFields-${
      //     this.isRTL ? 'سعر' : 'price'
      //   }`
      //   return false
      // }
      if (this.model.addons.length > 0) {
        for (const addon of this.model.addons) {
          if (addon.name.en.length === 0 && addon.name.ar.length === 0) {
            this.errorMessage = `notifications.create.popup.emptyFields-${
              this.isRTL ? 'اسم الاضافه' : 'addon name'
            }`
            return false
          }
          if (addon.price.length === 0) {
            this.errorMessage = `notifications.create.popup.emptyFields-${
              this.isRTL ? 'سعر الاضافه' : 'addon price'
            }`
            return false
          }
        }
      }
      if (this.model.sizes.length > 0) {
        for (const size of this.model.sizes) {
          if (size.name.en.length === 0 && size.name.ar.length === 0) {
            this.errorMessage = `notifications.create.popup.emptyFields-${
              this.isRTL ? 'اسم الحجم' : 'size name'
            }`
            return false
          }
          if (size.price.length === 0) {
            this.errorMessage = `notifications.create.popup.emptyFields-${
              this.isRTL ? 'سعر الحجم' : 'size price'
            }`
            return false
          }
        }
      }
      if (this.isEditing) {
        // if (!(this.videoUrl || this.filesToSaveVideo.length !== 0)) {
        //   this.errorMessage = `notifications.create.popup.emptyFields-${this.isRTL ? 'اسم' : 'name'}`
        //   return false
        // }
        if (
          !(
            this.imagesUrls ||
            this.filesToSaveImagesAdd.length !== 0 ||
            this.filesToSaveImagesNew.length !== 0
          )
        ) {
          this.errorMessage = `notifications.create.popup.emptyFields-${
            this.isRTL ? 'صورة' : 'image'
          }`
          return false
        }
      }
      return true
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(...this.errorMessage.split('-'))
        return Message.error(ERROR)
      }
      const {
        name,
        description,
        addons,
        sizes,
        categoryId,
        sectionId,
        orderBefore
      } = this.model

      if (this.isEditing) {
        await this.doUpdate({
          chefId: this.chefId,
          mealId: this.mealId,
          name,
          categoryId,
          sectionId,
          description,
          orderBefore,
          addons,
          sizes,
          video: this.videoUrl || this.filesToSaveVideo[0] || null,
          images: this.imagesUrls
            ? [...this.imagesUrls, ...this.filesToSaveImagesAdd]
            : this.filesToSaveImagesNew
        })
      }
    },
    doCancel() {
      this.$router.back()
    },
    i18n(key, ...args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
